.termsContainer{
    width: 100%;
    height: 20rem;
    overflow-y: scroll;
    border: 1px solid rgb(190, 190, 190);
    margin: 1% 0;
}
.containerSteps{
    width: 100%;
    padding-left: 1em;
}
.titleContainer{
    width: 90%;
    margin-top: 1%;
    padding: 2% 0;
}
.titleContainer h2{
    font-family: 'Catamaran', sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    color: #4DBC5E;
}
.titleContainer p{
    width: 100%;
    font-size: .9em;
    color: #292929;
}
.titleContainer p ul li a{
    text-decoration: none;
    color: #4DBC5E;
}
.containerSteps ul{
    font-size: .9em;
    width: 80%;
    color: #292929;
}
.containerSteps ul li{
    padding: 2% 0 0 0;
    
}
.containerSteps ul li img{
    padding: 2% 0 0 0;
}
.tycText{
    width: 90%;
    font-size: .9em;
    color: #797979;
}
span{
    width: 90%;
    font-size: .8em;
    text-align: center;
}
.checkbox{
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: start;
    font-size: .8em;
}
.checkbox input{
    width: 5%;
    height: 1rem;
}
.checkbox label{
    width: 95%;
}
.sendButton{
    padding: 1% 2%;
    margin-top: 6%;
    width: 80%;
    cursor: pointer;
    width: 8    0%;
    border: 1px solid rgb(77,188,94);
    font-size: .9em;
    color: rgb(77,188,94);
    background: #fff;
    transition: .3s;
}
.sendButton:hover{
    color: white;
    background: rgb(77,188,94);
    background: linear-gradient(45deg, rgba(77,188,94,1) 0%, rgba(3,102,103,1) 100%);
}