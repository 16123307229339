.selectSettingTypeContainer {
    display: flex; 
  }

  .selectSettingType {
    flex-grow: 1;
    padding: 1rem 0;
    background-color: transparent;
    font-size: 1.2rem;
    font-weight: 600;
    border: none;
    border-bottom: 1px solid transparent;
  }
  .selectSettingTypeActive{
    flex-grow: 1;
    padding: 1rem 0;
    background-color: transparent;
    font-size: 1.2rem;
    font-weight: 600;
    color: #6ae6ad;
    border-bottom: 2px solid #6ae6ad;
  }
  .selectSettingType:hover{
    color: #6ae6ad;
  }