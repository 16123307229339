.container{
  width: 90%;
}
.center {
    display: flex;
    justify_content: center;
    align_items: center;
  }
  .image_upload_wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .file_upload_content {
    display: none;
  }
  .file_upload_input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
  .image_upload_wrap {
    position: relative;
    height: 7rem;
    border: 1px solid rgb(190, 190, 190);
  }
  .text_information{
  }
  .text_information h3{
    text-align: center;
    font-weight: 400;
    font-size: 1em;
  }
.fileAdded{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fileAdded button {
  all:unset;
  margin-top: .3rem;
  z-index: 10;
  color: rgb(229, 70, 70);
}
  /* Estilos para pantallas pequeñas */
@media (max-width: 600px) {
  
}

/* Estilos para pantallas medianas */
@media (min-width: 601px) and (max-width: 1024px) {

}

/* Estilos para pantallas grandes */
@media (min-width: 1025px) {
  /* Estilos CSS aquí */
}