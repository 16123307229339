.cardContainer{
    display: flex;
    width: 80%;
    margin-bottom: 3%;
    cursor: pointer; 
    border: 1px solid #CFCFCF;
    border-radius: 3px;
    box-shadow: 0px 4px 5px -3px rgba(156,156,156,0.76);
    -webkit-box-shadow: 0px 4px 5px -3px rgba(156,156,156,0.76);
    -moz-box-shadow: 0px 4px 5px -3px rgba(156,156,156,0.76);
    transition: .5s;
}
.cardContainer:hover{
    transform: scale(1.02);
}
.infoContainer{
    width: 50%;
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.imageContainer{
    position: relative;
    width: 50%;
    overflow: hidden;
}
.imageContainer img{
    position: absolute; /* para que la imagen se posicione en la esquina superior izquierda del contenedor */
  width: 100%; /* para que la imagen ocupe todo el ancho disponible del contenedor */
  height: 100%; /* para que la imagen ocupe todo el alto disponible del contenedor */
  object-fit: cover;
}
.titleContainer{
    width: 95%;
    margin: 0 auto;
    
}
.title{
    width: 95%;
    font-size: 2em;
    font-weight: 700;
    color: #1d1d1d;
    padding-bottom: 1%;
}
.location{
    display: flex;
    align-items: center;
    font-size: .8em;
    font-weight: 400;
    padding: 0 0 1% 0;
}
.location div{
    margin-left: .3em;
}
.description{
    max-height: 5.2rem;
    overflow-y: scroll;
    width: 95%;
    margin: 0 auto;
    font-size: 1em;
    color: #797979;
    padding: 1% 0 1% 0;
    border-bottom: 1px solid #CFCFCF;
}
.description::-webkit-scrollbar {
    display: none;
  }
.info{
    width: 95%;
    margin: 0 auto;
    padding: 2% 0 0 0 ;
}
.infoProduct{
    padding: 1% 0;
}
.text{
    font-size: 1em;
    font-weight: 600;
}
.value{
    font-size: .9em;
    font-weight: 600;
    color: #797979;
    width: 98%;
    margin: 0 auto;
}
.tokensInfo{
    /* padding: 3% 0 0; */
    display: flex;
    justify-content: space-between;
    width: 50%;
}
.textTokensInfo{
    font-size: 1em;
    font-weight: 600;
}
.valueTokensInfo{
    font-size: 1.3em;
    font-weight: 700;
    color: #207E2E;
    width: 98%;
    margin: 0 auto;
}

@media (max-width: 800px) {
    .cardContainer{
        width: 100%;
        flex-direction: column-reverse;
    }
    .infoContainer{
        width: 100%;
        height: 50%;
    }
    .imageContainer{
        position: relative;
        width: 100%;
        height: 15rem;
    }
    .tokensInfo{
        width: 100%;
    }
    .title{
        font-size: 1.4em;
    }
    .description{
        font-size: .9em;
    }

    .value{
        font-size: .9em;
        font-weight: 600;
        color: #797979;
        width: 98%;
        margin: 0 auto;
    }
    .tokensInfo{
        /* padding: 3% 0 0; */
        display: flex;
        justify-content: space-between;
        width: 80%;
    }
  }
  
  /* Estilos para pantallas medianas */
  @media (min-width: 601px) and (max-width: 1200px) {
    .cardContainer{
        width: 100%;
    }
    .tokensInfo{
        /* padding: 3% 0 0; */
        display: flex;
        justify-content: space-between;
        width: 80%;
    }
    .textTokensInfo{
        font-size: 1em;
        font-weight: 600;
    }
    .valueTokensInfo{
        font-size: 1.3em;
        font-weight: 700;
        color: #207E2E;
        width: 98%;
        margin: 0 auto;
    }
  }
  
  /* Estilos para pantallas grandes */
  @media (min-width: 1200px) {
    /* Estilos CSS aquí */
  }