.supremeContainer{
    width: 100vw;
    height: 100vh;
    background-color: #7fc778;
    display: flex;

}
.container_success{
    background-color: #F8F9FA;
    margin: auto;
    width: 50%;
    height: 60%;
    border-radius: 30px;
    box-shadow: 0px 0px 40px -30px rgb(83, 83, 83);
    display: flex;
    flex-direction: column;

}
.title{
    text-align: center;
}
.text{
    text-align: center;
    padding-top: 2vh;
}
.textMarket{
    display: flex;
    align-items:flex-end;
    justify-content: center;
    padding-top: 5px;

}
.textMarket img{

    margin-bottom: -4.3px;
}
.button{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}


.imgCarritoThanks{
  margin-left: 3px;
  padding-bottom: 2px;
}
@media (max-width: 1000px){
  .container{
    width: 80vw;
    height: 74vh;
  }

}
@media (max-width: 500px){
  .containerButton{
    padding-top: 8vh;
  }

}



.containerCheck{
  display: grid;
  height: 20vh;
  place-items: center;
  background: transparent;
  margin-top: 2vh;
}
.label{
    margin-top: 30px;
  position: relative;
  height: 105px;
  width: 105px;
  display: inline-block;
  border: 2px solid rgba(255,255,255,0.2);
  border-radius: 50%;
  border-left-color: #5cb85c;
  animation: rotate 1.2s linear infinite;
}
@keyframes rotate {
  50%{
    border-left-color: #5cb85c;
  }
  75%{
    border-left-color: #5cb85c;
  }
  100%{
    transform: rotate(360deg);
  }
}
.label .checkIcon{
  display: none;
}
.label .checkIcon:after{
  position: absolute;
  content: "";
  top: 50%;
  left: 28px;
  transform: scaleX(-1) rotate(135deg);
  height: 40px;
  width: 20px;
  border-top: 4px solid #5cb85c;
  border-right: 4px solid #5cb85c;
  transform-origin: left top;
  animation: checkIcon 0.8s ease;
}
@keyframes checkIcon {
  0%{
    height: 0;
    width: 0;
    opacity: 1;
  }
  20%{
    height: 0;
    width: 20px;
    opacity: 1;
  }
  40%{
    height: 40px;
    width: 20px;
    opacity: 1;
  }
  100%{
    height: 40px;
    width: 20px;
    opacity: 1;
  }
}
.input{
  display: none; 
}
.input:checked ~ .label .checkIcon{
  display: block;
}
.input:checked ~ .label{
  animation: none;
  border-color: #5cb85c;
  transition: border 0.5s ease-out;
}

@media all and (max-width: 500px) {
  .container {
    width: 90vw !important;
  }  

  .containerButton {
    margin: 50px 0 0 0 !important;
    padding: 0 !important;
    position: initial !important;
  }
}