.container{
    min-height: 100vh;
    background-image: url('../_images/0162_cesar_david_martinez.jpg');
    display: flex;
    align-items: center;
}
.firstContainer{
    width: 60%;
    display: flex;
    justify-content: center;

}
.secondContainer{
    width: 40%;
}
.info{
    width: 60%;
    color: white;
}
.info h2{
    font-size: 3em;
}
.info p{
    font-size: 1.4em;
    width: 90%;
}
.containerCard{
    width: 30rem;
    border-radius: 8px;
    background-color: white;
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.containerTitle{
    padding: 2% 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.containerTitle h2{
    padding: 3% 0 0 0;
    font-size: 1.2em;
}
.inputContainer{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.inputContainer fieldset{
    padding: 2% 0;
    width: 100%;
}
.inputContainer legend{
    font-size: .8em;
}
.inputContainer input{
    width: 100%;
    height: 2.5rem;
    font-size: .9em;
}
.inputContainer select{
    width: 100%;
    height: 2.5rem;
    font-size: .9em;
}
.forgotPasswordSpan{
    width: 100%;
    font-size: .7em;
    color: #a1a1a1;
}
.checkbox{
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: .8em;
}
.checkbox input{
    width: 5%;
    height: 1rem;
}
.checkbox label{
    width: 95%;
}
.inputContainer button{
    padding: 3% 6%;
    margin-top: 6%;
    cursor: pointer;
    width: 70%;
    border: 1px solid rgb(77,188,94);
    font-size: .9em;
    color: rgb(77,188,94);
    background: #fff;
    transition: .3s;
}
.inputContainer button:hover{
    color: white;
    background: rgb(77,188,94);
    background: linear-gradient(45deg, rgba(77,188,94,1) 0%, rgba(3,102,103,1) 100%);
}
.needAccount{
    padding: 2% 0 0 0;
}
.needAccount span{
    color: rgba(77,188,94,1);
}
/* Estilos para pantallas pequeñas */
@media (max-width: 800px) {
.container{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.firstContainer{
    display: none;
}
.secondContainer{
    width: 100%;
    height: 100%;
}

.containerCard{
    margin: 0 auto;
    width: 95%;
}
  }
  
  /* Estilos para pantallas medianas */
  @media (min-width: 801px) and (max-width: 1200px) {
    .firstContainer{
        width: 50%;
        display: flex;
        justify-content: center;
    
    }
    .secondContainer{
        width: 50%;
    }
    .info{
        width: 60%;
        color: white;
    }
    .info h2{
        font-size: 3em;
    }
    .info p{
        font-size: 1em;
        width: 90%;
    }
    .containerCard{
        width: 70%;
        border-radius: 8px;
        background-color: white;
        padding: 2% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }
    /* Estilos para pantallas medianas */
    @media (min-width: 1201px) and (max-width: 1500px) {
        .firstContainer{
            width: 50%;
            display: flex;
            justify-content: center;
        
        }
        .secondContainer{
            width: 50%;
        }
        .info{
            width: 60%;
            color: white;
        }
        .info h2{
            font-size: 3em;
        }
        .info p{
            font-size: 1em;
            width: 90%;
        }
        .containerCard{
            width: 70%;
            border-radius: 8px;
            background-color: white;
            padding: 2% 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
      }
  /* Estilos para pantallas grandes */
  @media (min-width: 1500px) {
    /* Estilos CSS aquí */
  }