.card {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.header {
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  text-align: center;
}

.content {
  padding: 1rem;
}

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.value {
  margin-left: 1rem;
}

@media (min-width: 768px) {
  .card {
    margin: 1rem auto;
    max-width: 768px;
  }

  .content {
    padding: 1rem 2rem;
  }

  .header {
    border-radius: 4px 4px 0 0;
  }

  .row {
    flex-direction: row;
    justify-content: space-between;
  }

  .value {
    margin-left: 0;
  }
}

@media (min-width: 1024px) {
  .card {
    max-width: 1024px;
  }

  .row {
    flex-wrap: wrap;
  }

  .label {
    width: 33.33%;
  }

  .value {
    width: 66.67%;
    margin-left: 0;
    }
  }
