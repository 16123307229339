.container{
    background-image: url('../_images/fondo_landinf_banner.jpg');
    background-repeat: no-repeat;   
    background-size: cover;
    height: 80vh;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
    color: white;
    padding-left: 2%;
}
a{
    text-decoration: none;
}
::marker {
    font-weight: 700;
}
.container h1{
    font-size: 3em;
    font-weight: 700;
    width: 60%;
}
.container p{
    font-size: 2em;
    width: 80%;
}
.container button{
    margin-bottom: 5%;
    padding: .7% 0;
    width: 25%;
    font-size: 1.2em;
    cursor: pointer;    
    border: none;
    color: white;
    background: rgb(77,188,94);
    background: linear-gradient(45deg, rgba(77,188,94,1) 0%, rgba(3,102,103,1) 100%);
    transition: .2s;
}
.container button:hover{
    transform: scale(1.01);
}
.titleContainerProducts{
    padding-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.title{
    font-size: 4em;
    font-family: 'Catamaran', sans-serif;
    color: #207E2E;
}
/* .titleContainerProducts h3{
    font-family: 'Catamaran', sans-serif;
    font-size: 3em;
    font-weight: 700;
}
.titleContainerProducts p{
    text-align: center;
    width: 60%;
    padding-top: 1%;
    font-size: 1.2em;
} */
.containerFeaturedProducts{
    width: 100%;
    display: flex;
    margin: 1% 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.contactContainer{
    width: 100%;
    margin-top: 8%;
    margin-bottom: 8%;
    border-top: 2px solid #DCDCDC;
    border-bottom: 2px solid #DCDCDC;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactContainer h2{
    font-family: 'Catamaran', sans-serif;
    padding: 3% 0 1% 0;
    font-size: 3em;
    font-weight: 700;
    color: #4E4E4E
}
.contactContainer a{
    width: 10%;
    margin-bottom: 3%;
}
.contactContainer button{
    padding: 4% 2%;
    width: 100%;
    
    border-radius: 6px;
    border: none;
    font-size: 1.1em;
    color: white;
    background: rgb(77,188,94);
    background: linear-gradient(45deg, rgba(77,188,94,1) 0%, rgba(3,102,103,1) 100%);
}
@media (max-width: 600px) {

    .container h1{
        font-size: 2.2em;
    }
    .container p{
        padding-top: 6%;
        font-size: 1em;
        width: 100%;
    }
    .container button{
        margin-bottom: 10%;
        padding: 2% 4%;
        width: 70%;
        font-size: .9em;
    }
    .titleContainerProducts{
        padding-top: 3%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .title{
        margin-top: 2%;
        font-size: 1.7em;
    }
    /* .titleContainerProducts h3{
        margin-top: 2%;
        font-size: 2em;
    }
    .titleContainerProducts p{
        width: 95%;
        font-size: .9em;
    } */
    .containerFeaturedProducts{
        width: 95%;
        margin: 2% auto;
        flex-direction: column;
    }
    .contactContainer h2{
        font-size: 1.7em;
    }
    .contactContainer a{
        width: 30%;
        margin-bottom: 3%;
    }
    .contactContainer button{
        padding: 2% 4%;
        font-size: .9em;
    }
  }
  
  /* Estilos para pantallas medianas */
  @media (min-width: 601px) and (max-width: 1024px) {
    .container{
        padding-top: 20%;
    }
    .container h1{
        font-size: 3.2em;
    }
    .container p{
        width: 80%;
    }
    .title{
        margin-top: 2%;
        font-size: 3em;
    }

   
  }
  
  /* Estilos para pantallas grandes */
  @media (min-width: 1025px) {
    /* Estilos CSS aquí */
  }