.mensaje {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .mensaje p {
    color: white;
    font-size: 24px;
    text-align: center;
    padding: 20px;
    max-width: 80%;
  }
  