.cardContainer:hover{
    transition: .1s;
 }
 .cardContainerSelected{
    transform: scale(1.01);
 }
 .cardContainerSelected:hover{
     transition: .1s;
  }
 
 .cardContainer:hover{
     transform: scale(1.01);
 }
.cardContainer{
    cursor: pointer;
    margin-bottom: 10px;
}
.cardContainerSelected{ 
    border: 1px solid #005BAA;
    cursor: pointer;
    margin-bottom: 10px;
}