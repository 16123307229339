.container_profile{
    width: 90%;
    height: 20%;
    display: flex;
    padding: 20px 0 20px 0;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 10px;
    box-shadow: 0px 5px 5px 1px rgb(0 0 0 / 3%);
}
.profile_image_container{
    width: 10%;
    display: flex;
    margin: 0 20px 0 10px;
    justify-content: center;
}
.profile_image{
    width: 150px;
    height: auto;

}
.profile_info_container{
    width: 90%;
    display: flex;
}
.profile_info{
    width: 60%;
}
.button_update_profile{
    display: flex;
    align-items: flex-end;
    border: 1px solid red;
    background-color: transparent;
    border: none;
    color: #00AAE4;
}